@use "theme.scss" as theme;

$animationDuration: .85s;
// Export this duration to be used in Switch JS
:export { animationDurationInSeconds: $animationDuration; }

.SwitchCard {
	width: theme.$playerWidth;
	height: theme.$playerHeight + theme.$controlBarHeightVh;
	transition: transform $animationDuration ease-in-out;
	transform-style: preserve-3d;
}

.Front, .Back {
	@include theme.flex-column-centered;
	// margin: 0 auto;
}

.Front {
	position: absolute;
	z-index: 100;
	
	top: 0;
	left: 50%;
	// @include theme.card-max-width(1100px); // Removed as it was causing the player to be off-center in LPV
	transform: translate(-50%, 0);
	transition: top 0.7s cubic-bezier(0.3, 0.21, 0.21, 1.25);
}

.Back {
	transition: opacity 0.5s ease-out;
	opacity: 0;
}

.Switched {
	.Front {
		top: -220vh;
	}
	.Back {
		opacity: 1;
	}
}
