@use "src/theme.scss" as theme;

.CarouselCard {
  padding: 0 0 30px;
  width: 100%;

  & :global(.carousel-initialized) {
    overflow: visible;
  }
  & :global(.carousel-item article) {
    margin: 0 20px;
  }
  & :global(.carousel-arrow:before) {
    display: none;
  }

  & :global(ul.carousel-dots) {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;

    & li {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 5px;
      border-radius: 50%;
      box-shadow: theme.$shadow;
      background: theme.$white;
      cursor: pointer;

      & button {
        display: none;
      }

      &:global(.carousel-dots-active) {
        background: theme.$primaryColor;
      }
    }
  }
}
