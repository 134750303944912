@use "src/theme.scss" as theme;
@use "src/components/Common/Card.module.scss";

header {
	display: none
}

.ErrorCard {
	@extend .Card;
	@include theme.card-max-width(400px);
	width: calc(100% - 40px);

	.Content {
		@include theme.flex-column-centered;
		justify-content: flex-start;
		text-align: center;
		gap: theme.$cardContentGap;

		.ErrorCode {
			@include theme.title-gen(2.2rem);
			line-height: normal;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			position: relative;
			text-align: center;
			z-index: 0;
			margin: -3px 0 0;
			padding: 10px 20px;
			@include theme.title-gen(1.3rem);
			letter-spacing: 1px;
			transform: rotate(1deg);
			background: theme.$paperBackground no-repeat;
			background-size: cover;
			box-shadow: theme.$shadow;
			@include theme.bkgBlend(#eee, soft-light);
		}
		
		.ErrorSubtitle {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			position: relative;
			text-align: center;
			z-index: 0;
			gap: theme.$cardContentGap;
			// margin: 30px 0 2px;
			padding: 10px 30px;
			@include theme.title-gen(2.5rem);
			letter-spacing: 1px;
			transform: rotate(-2deg);
			background: theme.$paperBackground no-repeat;
			background-size: cover;
			box-shadow: theme.$shadow;
			@include theme.bkgBlend(theme.$quinternaryColor, color);
		}

		.ErrorText {
			@include theme.text-gen(1rem);
			font-family: theme.$mainFont;
			white-space: pre-line;
		}
	}
}
