@use "src/theme.scss" as theme;

:global(.embedded){
	background: none;
	body{
		background: none !important;
	}
	:global(.kaltura-player-container){
		background-color:transparent;
	}
	:global(.playkit-poster){
		background-color:transparent;
		border-radius: 0;
	}
	:global(.playkit-engine){
		border-radius: 0;
	}
	:global(.playkit-video-player){
		background-color:transparent;
	}
	.playerContainer{
		width:calc((100vh - 8vh) / 16 * 9);
		height: calc(100vh - 8vh);
		border-radius: 0;
	}
	:global(#player){
		margin:0 auto;
		width: auto;
	}
}

:global(#player) {
	position: relative;
	@include theme.flex-column-centered;
	margin: 3vmin auto;
}

.playerContainer {
	align-self: center;
	height: theme.$playerHeight;
	width: theme.$playerWidth;
	position: relative;
	overflow: hidden;
}

.playerContainer > div {
	height: 100%;
	width: 100%;
}

.playerContainer > * {
	backface-visibility: hidden;
}

:global(.playkit-poster) {
	box-shadow: inset 0px 4px 100px 10px rgba(0, 0, 0, 0.2);
	height: 102%;
	width: 102%;
	filter: brightness(1);
	&:after {
		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-image: linear-gradient(
			179deg,
			rgb(64 63 103 / 20%),
			rgba(64 63 103 / 0.7)
		);
	}
}

/* Remove kaltura player bottom bar, we have our own controls for that */
/* Remove kaltura player top gradient on pause (only visible on Safari?, was conflicting with rounded corners */
:global(.playkit-bottom-bar),
:global(.playkit-top-bar) {
	display: none;
}

:global(.playkit-tooltip),
:global(.playkit-icon-play) {
	display: none !important;
}

:global(.playkit-video-player, .kaltura-player-container, .playkit-poster, .playkit-black-cover) {
	background-color: transparent !important;
}
:global(.playkit-pre-playback-play-overlay .playkit-pre-playback-play-button) {
	height: 100px !important;
	width: 200px !important;
	background-color: transparent !important;
	border: 0 !important;
}
:global(.playkit-pre-playback-play-overlay:hover) {
	cursor: pointer;
	transform: scale(1.07);
	transition: transform ease-out 0.05s;
	text-shadow: 2px 2px 2px #777;
}

:global(.playkit-pre-playback-play-overlay > button::after) {
	content: '[play]';
	color: #fafafa;
	font-size: 30px;
	font-family: 'MAAX-MONO---BOLD-205TF', Monospace;
}

:global(.kaltura-player-container, .playkit-black-cover) {
	background-color: transparent !important;
}

:global(.playkit-playback-controls.playkit-center-playback-controls) {
	top: 5% !important;
	left: 90% !important;
}

.EntireContainer {
	position: relative;
	min-height: 83vh; // @TODO Better strategy for this magic number?
	transition: transform theme.$navAndVideoTransition;
	transition-delay: theme.$delayBeforeNavAndVideoTransition;
	/* if the video is playing OR in voting sequence */
	:global(body.playing) &,
	:global(body.voting) & {
		@include theme.small-screen {
			transform: scale(1.11);
		}
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

.PulseSlowly {
	animation: pulse 2s 10;
}

.TutorialIcon {
	color: theme.$white;
}
