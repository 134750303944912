@use 'src/theme.scss' as theme;

.CardContent {
   //  overflow-y: auto;
    @include theme.flex-column;
    gap: theme.$cardContentGap;
	 font-weight: 300;
}

.StepImage {
    max-width: 100%;
    width: auto;
    max-height: min(20vh, 100px);
    height: auto;
    align-self: center;
}

.ScrollableArea {
	@include theme.scroll-bar(50vh);
	display: block;
	@include theme.large-screen {
		@include theme.scroll-bar(200px);
	}
	.Content {
		padding: 5px 10px;
	}
	p {
		text-align: center;
		margin: 0;
		line-height: 1.5rem;
		&:not(:first-of-type) {
			margin-top: 2rem;
		}
	}
}


///////////////////////////
// WELCOME CARD
///////////////////////////

.ReceptionTimeContainer {
    @include theme.flex-row-centered;
	gap: theme.$cardContentGap;
	align-self: center;
    align-items: flex-start;
    justify-content: space-evenly;
    width: min(100%, 250px);
    color: theme.$black;
	@media (prefers-color-scheme: dark){
		color: theme.$white;
	}

    .DaysContainer,
    .HourContainer {
        @include theme.flex-column-centered;
        width: 50%;

        .Icon {
            width: auto;
            height: min(5vh, 50px);
            margin-bottom: 1rem;
        }
    }
}


///////////////////////////
// TERMS OF SERVICE CARD
///////////////////////////

.TOSContent {
    @include theme.flex-column;
    max-width: 500px;
    gap: 5px;
}

// .SignUpForm {
// 	@include theme.large-screen {
// 		@include theme.flex-row;
// 		align-items: center;
// 		flex-wrap: wrap;
// 		margin-left: -1.2rem;
// 		margin-right: -1.2rem;
// 		> * {
// 			flex-basis: calc(50% - 2.4rem);
// 			width: calc(50% - 2.4rem);
// 			margin: 0 1.2rem 2.4rem !important;
// 		}
// 		> div {
// 			flex-basis: calc(100% - 2.4rem);
// 			width: calc(100% - 2.4rem);
// 		}
// 	}
// }


///////////////////////////
// FORM CARD
///////////////////////////

.PhoneField { // overrides TelInput default styling
    input {
        width: -webkit-fill-available;
    }
}

///////////////////////////
// THANK YOU CARD
///////////////////////////

.ThankYouCard {
	@include theme.flex-column-centered;
    gap: theme.$cardContentGap;
	width: 100%;
	max-width: 400px;

	.ThankYouContainer {
		@include theme.flex-column-centered;
		width: 100%;
	}

    .ButtonsContainer {
		@include theme.flex-column-centered;
        gap: theme.$cardContentGap;
		align-self: center;
	}
	
	@include theme.large-screen {
		flex-direction: row;
		justify-content: space-around;
	}


    .ThankYou {
        @include theme.title-gen(4rem);
		color: theme.$tertiaryColor;
	}

    .ThankYouText {
        max-width: 100%
    }

	.SubscriberName {
		box-sizing: border-box;
		display: block;
		margin-bottom: 2rem;
		@include theme.title-gen(4rem);
		color: theme.$secondaryColor;
		padding: 20px;
        max-width: 100%;
        text-align: center; // for longer names
	}

	.FirstVideoContainer {
		margin-bottom: 3rem;
		display: flex;
		flex-wrap: wrap;
		span {
			@include theme.text-gen(1.3rem);
			&:last-child {
				display: block;
				// margin-top: 1rem;
				opacity: 0.8;
			}
		}
	}

	.Icon {
		display: none;
	}

	.Link {
		margin-top: 2rem;
		align-self: center;
		@include theme.title-gen(1.4rem);
		color: theme.$secondaryColor;
		text-decoration: none;
	}
}