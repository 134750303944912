@use "src/theme.scss" as theme;

@font-face {
	font-family: 'Apercu';
	src: local('Apercu'), url(./static/fonts/ApercuRegular.ttf) format('truetype');
}
@font-face {
	font-family: 'Apercu Bold';
	src: local('ApercuBold'),
		url(./static/fonts/ApercuBold.ttf) format('truetype');
}
@font-face {
	font-family: 'MaaxMono';
	src: local('MaaxMono'),
		url(./static/fonts/MaaxMonoRegular.ttf) format('truetype');
}

html {
	overflow-x: hidden;
	scroll-behavior: smooth;
	@media (prefers-reduced-motion: reduce) {
		scroll-behavior: auto;
	}
	@include theme.large-screen {
		font-size: 16px;
	}
	@include theme.medium-screen {
		font-size: 14px;
	}
	@include theme.small-screen {
		font-size: 12px;
	}
	@include theme.extra-small-screen {
		font-size: 11px;
	}
}

body {
	font-family: theme.$mainFont;
	margin: 0;
	min-width: 320px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	user-select: none;
	@include theme.text-gen(1rem, 800); // @todo: this was added to cap & gv to remove text gen from various places
	@media (prefers-color-scheme: dark){
		color: theme.$white;
	}
}

h1,
h2,
h3,
h4,
h5,
p {
	margin: unset;
}

strong {
	font-family: theme.$mainFontBold;
}

a {
	color: theme.$secondaryColor;
	text-decoration-color: theme.$secondaryColor;
}

h1 {
	display: inline-flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 0;
	// margin: 0 auto 30px; // @todo: check if nothing broke. We want to handle paddings individually and not globally
	padding: 10px 30px;
	@include theme.title-gen(1.8rem);
	letter-spacing: 1px;
	transform: rotate(-1deg);
	background: theme.$paperBackground no-repeat;
	background-size: cover;
	box-shadow: theme.$shadow;
	text-transform: uppercase;
	@include theme.bkgBlend(theme.$quinternaryColor, color);
	@media (prefers-color-scheme: dark){
		color: theme.$white;
	}
}

h2 {
	@include theme.title-gen(2rem);
	color: theme.$black;
	text-align: center;
	@media (prefers-color-scheme: dark){
		color: theme.$white;
	}
}

h3 {
	@include theme.title-gen(1.5rem);
	color: theme.$black;
	@media (prefers-color-scheme: dark){
		color: theme.$white;
	}
}

h4 {
	@include theme.title-gen(1.3rem);
	color: theme.$black;
	@media (prefers-color-scheme: dark){
		color: theme.$white;
	}
}
a{
	@media (prefers-color-scheme: dark){
		color: theme.$white;
	}
}