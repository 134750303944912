@use "src/theme.scss" as theme;

.Card {
	@include theme.card-max-width;
	@include theme.flex-column-centered;
	position: relative;
	box-sizing: border-box;
	gap: theme.$cardSpacing;
	padding: theme.$cardSpacing; // Note: For cards with a header, header has a margin-bottom of the same size, to make sure the gutter / padding is uniform on top / bottom / sides
	min-width: min-content;
	height: fit-content;
	margin-top: 2.5vmin; // separate cards from each other when there are several
	margin-bottom: 2.5vmin; // separate cards from each other when there are several
	margin-left: 2vmin; // create space between card and the border of the screen
	margin-right: 2vmin; // create space between card and the border of the screen
	@include theme.small-screen {
		padding: theme.$smallCardSpacing;
	}
	&:before {
		position: absolute;
		z-index: 0;
		top: 2px;
		left: 2px;
		right: 2px;
		bottom: 2px;
		content: '';
		background: #fff theme.$cardBeforeBackground no-repeat center;
		background-size: cover;
		box-shadow: theme.$cardShadow;
		transform: rotate(2deg);
		@include theme.large-screen {
			top: 5px;
			left: 5px;
			right: 5px;
			bottom: 5px;
			transform: rotate(4deg);
		}
	}

	&:after {
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		content: '';
		background: #fff theme.$cardContentBackground no-repeat center;
		background-size: cover;
		box-shadow: theme.$cardShadow;
	}
	@media (prefers-color-scheme: dark){
		&:before{
			background: theme.$dmColorZ2;
		}
		&:after{
			background: theme.$dmColorZ1;
		}
	}

	& > * {
		position: relative;
		z-index: 1;
		max-width: 90%;
	}

	ol {
		@include theme.flex-column;
		align-items: left;
		list-style: none;
		text-align: left;
		counter-reset: li;
		li {
			counter-increment: li;
			margin-bottom: 10px;
			padding-right: 10px;
			min-width: 60%;
			&::before {
				@include theme.text-gen(0.9rem, 600);
				content: counter(li) '.';
				display: inline-block;
				margin-right: 0.5em;
				color: theme.$tertiaryColor;
			}
		}
	}

	ul {
		@include theme.flex-column;
		align-items: left;
		list-style: none;
		text-align: left;
		padding-left: 0;
		li {
			line-height: 1.5rem;
		}
	}

	p {
		@include theme.text-gen(0.9rem);
		text-align: justify;
	}

	legend {
		@include theme.text-gen(0.9rem);
	}
}

.Header:not(:empty) {
	box-sizing: content-box;
	margin-right: auto;
	margin-left: auto;
	@include theme.flex-column;
	color: theme.$black;
	box-shadow: theme.$cardShadow;
	padding: theme.$cardSpacing;
	min-width: min-content;
	height: fit-content;
	min-height: 20px;
	width: calc(100% + calc(theme.$cardSpacing * 2));
	margin: theme.negative-value(theme.$cardSpacing) theme.negative-value(theme.$cardSpacing) 0px;
	margin-bottom: theme.$cardSpacing; // Note: this is to make sure cards with a header have the same gutter between the top of the card and its content, and the other sides
	@include theme.title-gen(1.5rem);
	color: theme.$black;

	// & > * {
	// if you put something in the header, make sure it has enough padding
	// padding: min(2vh, 2vw);
	// }
}
