@use 'src/theme.scss' as theme;

.Navigation {
	width: 100%;
	background: theme.$white;
	position: fixed;
	z-index: 2;
	left: 0;
	box-shadow: theme.$cardShadow;
	display: flex;

	&.Show {
		opacity: 1;
		transition: opacity 0.2s;
	}
	&.Hide {
		opacity: 0;
		transition: opacity 0.2s;
		pointer-events: none;
	}
	@media (prefers-color-scheme: dark){
		background-color: theme.$dmColorZ1;
	}
	@include theme.small-screen {
		width: auto;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
		border-radius: 100px;
		box-shadow: theme.$cardShadow;
		background: theme.$primaryColor;
	}

	@include theme.large-screen {
		top: 0;
		left: 0;
	}

	& > div:first-child{
		background: theme.$headerBackground;
		@include theme.flex-row-centered;
		@media (prefers-color-scheme: dark){
			background-color: theme.$dmColorZ1;
		}
		@include theme.small-screen {
			display: none;
		}
	}

	a {
		@include theme.text;
		color: theme.$black;
		text-decoration: none;
		text-align: center;
		padding: 8px;
		margin: 0 8px;
		transition: background-color 0.5s;
		&:first-of-type{
			margin-left: 0;
		}
		&:last-of-type{
			margin-right: 0;
		}
		@media (prefers-reduced-motion: reduce) {
			transition: none;
		}
		@include theme.small-screen {
			color: theme.$white;
			border-radius: 50%;
			margin-left: 3px;
			margin-right: 3px;
			span {
			  @include theme.hidden;
			}
		  }
		  @include theme.extra-small-screen {
			margin-left: 1px;
			margin-right: 1px;
		  }
		span {
			white-space: nowrap;
		}

		&:hover,
		&.Current {
			@include theme.large-screen {
				color: theme.$primaryColor;
			}
			@include theme.small-screen {
				background-color: theme.$white;
			  }
			& svg {
				color: theme.$primaryColor;
				fill: theme.$primaryColor;
			}
		}

		& svg {
			width: 24px;
			height: 24px;
			margin: 8px;
			transition: fill 0.5s;
			@media (prefers-reduced-motion: reduce) {
				transition: none;
			}
		}
	}
}
