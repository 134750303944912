@use "src/theme.scss" as theme;
@use "src/components/Common/Card.module.scss";

.BiographyCard {
	@extend .Card;
	@include theme.card-max-width(1100px);

	@include theme.medium-screen {
		@include theme.card-max-width(650px);
		gap: theme.$cardContentGap;
	}

	@include theme.large-screen {
		margin-top: theme.$cardContentGap;
		padding: 0;
		flex-direction: row;
		align-items: stretch;

		& > header {
			display: none;
		}

		& > * {
			padding: theme.$cardSpacing;
		}
	}

	& > :nth-child(3) {
		@include theme.flex-column;
		gap: theme.$cardContentGap;
	}
}

.PersonInfo {
	@include theme.flex-column-centered;
	position: relative;
	text-align: center;
	margin: 0 auto;
	min-width: 200px;
	flex-shrink: 0;
	margin-top: theme.negative-value(
		theme.$cardContentGap
	); // important: needed because this card is not built like other cards, grid-gap is applied above the person info. This "cancels" it.
	white-space: pre-line;

	@include theme.large-screen {
		max-width: 470px;
		margin: -10px 0 -10px -10px;
		transform: rotate(-2deg);
		background: theme.$paperBackground3 no-repeat;
		background-size: cover;
		box-shadow: theme.$shadow;
		@media (prefers-color-scheme: dark) {
			background: theme.$dmColorZ2;
		}
	}

	.Avatar {
		width: 150px;
		height: 150px;
		border: 2px solid theme.$white;
		border-radius: 50%;
		background-color: theme.$white;
		@include theme.small-screen {
			position: absolute;
			top: -58px;
			left: calc(50% - 38px);
			width: 76px;
			height: 76px;
		}
	}

	.Name {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		position: relative;
		text-align: center;
		z-index: 0;
		margin: 30px 0 2px;
		padding: 10px 30px;
		@include theme.title-gen(1.8rem);
		letter-spacing: 1px;
		transform: rotate(-2deg);
		background: theme.$paperBackground no-repeat;
		background-size: cover;
		box-shadow: theme.$shadow;
		@include theme.bkgBlend(
			theme.$quinternaryColor,
			color,
			theme.$dmColorZ4
		);
	}

	.Title {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		position: relative;
		text-align: center;
		z-index: 0;
		margin: -3px 0 0;
		padding: 10px 20px;
		@include theme.title-gen(1.3rem);
		letter-spacing: 1px;
		transform: rotate(1deg);
		background: theme.$paperBackground no-repeat;
		background-size: cover;
		box-shadow: theme.$shadow;
		@include theme.bkgBlend(#eee, soft-light, theme.$dmColorZ3);
	}

	.Company {
		margin: 0;
		&:not(:empty) {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			position: relative;
			z-index: 0;
			margin: -1px 0 0;
			padding: 7px 10px;
			@include theme.title-gen(1rem);
			letter-spacing: 1px;
			transform: rotate(3deg);
			background: theme.$paperBackground no-repeat;
			background-size: cover;
			box-shadow: theme.$shadow;
			@include theme.bkgBlend(#eee, soft-light, theme.$dmColorZ4);
			img {
				max-width: 100px;
				height: auto;
			}
		}
	}
}

.Content {
	@include theme.text;
	p,
	ul {
		margin: theme.$cardContentGap 0;
		&:first-child {
			margin-top: 0;
		}
	}

	@include theme.large-screen {
		line-height: 1.25rem;
	}

	p,
	ul {
		margin: 20px 0;
		&li:first-of-type {
			margin-top: 0;
		}
		&li:last-of-type {
			margin-bottom: 0;
		}
	}
}

.SocialProfiles {
	@include theme.flex-row-centered;
	flex-wrap: wrap;
	max-width: 100%;

	@include theme.large-screen {
		justify-content: start;
	}

	img {
		height: max(50px, 5vmin);
		width: max(50px, 5vmin);
	}
}
