@use "src/theme.scss" as theme;

.Cta {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	min-height: 60px;
	position: relative;
	z-index: 1;
	padding: 10px 40px;
	text-decoration: none;
	cursor: pointer;
	font-family: theme.$secondaryFont;
	font-size: 1.4rem;
	color: theme.$white;
	letter-spacing: 1px;
	transition: all theme.$transitionDuration;
	border: none;
	outline: none;
	box-shadow: theme.$shadow;
	@include theme.bkgBlend(theme.$primaryColor, hard-light,theme.$dmColorZ3);
	
	margin: 0 auto;
	transform: rotate(1deg);

	&:hover,
	&:focus,
	&:active {
		transform: scale(1.1);
	}
	&:disabled {
		opacity: 0.7;
	}
	&.PulseSlowly {
		animation: pulse 2s infinite;
	}

	&.Secondary {
		color: theme.$primaryColor;
		&:before {
			background: theme.$secondaryColor;
		}
		@media (prefers-color-scheme: dark){
			color: theme.$white;
			&:before {
				background: theme.$dmColorZ4;
			}
		}
	}
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
	}
	50% {
		transform: scale(1.05);
	}
	100% {
		transform: scale(0.95);
	}
}
