@use 'src/theme.scss' as theme;
@use "src/components/Common/Card.module.scss";

$horizontalSize: 28%;
.StepperCard {
	@extend .Card;
	@include theme.flex-column-centered;
	// @include theme.card-max-width(800px);
	width: min(800px, 90vw);	

	@include theme.small-screen {
		padding: theme.$smallCardSpacing;
	}
	
	p {
		align-self: flex-start;
	}

	.StepContentContainer {
		@include theme.flex-column-centered;
		max-width: 100%;
		gap: theme.$cardContentGap;
		height: fit-content;
		> div {
			gap: theme.$cardContentGap;
			@include theme.flex-column-left;
		}
	}
}

.Stepper {
	position: relative;
	width: 100%;
	padding: theme.$cardSpacing;
	box-sizing: border-box;
}

.SingleStep {
	@include theme.flex-column-left;
	flex-direction: column-reverse;

	.NumberContainer {
		position: absolute;
		bottom: theme.$cardSpacing;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: theme.$quaternaryColor;
		opacity: 0.3;
		@media (prefers-color-scheme: dark){
			background: theme.$dmColorZ3;
		}

		.Number {
			display: none;
		}

		&.Active,
		&.Current {
			background-color: theme.$tertiaryColor;
			opacity: 1;
			@media (prefers-color-scheme: dark){
				background: theme.$white;
			}
		}

		&.Current {
			& + .LegendContainer {
				display: block;
			}
		}
	}

	&:nth-child(1) {
		.NumberContainer {
			left: theme.$cardSpacing;
		}
	}

	&:nth-child(3) {
		// 3 because there is an empty div between 1 and 3
		.NumberContainer {
			left: theme.$cardSpacing + 30px;
		}
	}

	&:nth-child(5) {
		// 5 because there is an empty div between 3 and 5
		.NumberContainer {
			left: theme.$cardSpacing + 60px;
		}
	}

	.Legend {
		display: none;
	}

	.Line {
		display: none;
	}
}
