@use 'src/theme.scss' as theme;

.ToggleButton {
	@include theme.flex-row-centered;
	align-items: stretch;

	&:focus {
		outline: none;
	}
	&:focus-visible {
		outline: 1px solid theme.$tertiaryColor;
	}

	.Option {
		padding: 0.8rem 2rem;
		color: theme.$black;
		text-align: center;
		&:first-of-type {
			border-radius: 2px 0 0 2px;
		}
		&:not(:first-of-type):not(:last-of-type) {
			border-right: none;
			border-left: none;
		}
		&:last-of-type {
			border-radius: 0 2px 2px 0;
		}
		&[aria-pressed='true'] {
			background: theme.$tertiaryColor;
			color: theme.$white;
			@media (prefers-color-scheme: dark){
				background: theme.$dmColorZ4;
			}
		}
		&:not([aria-pressed='true']) {
			background: theme.$white;
			@media (prefers-color-scheme: dark){
				color: theme.$white;
				background-color: theme.$dmColorZ2;
			}
		}
		&:not([aria-pressed='true']):hover {
			font-weight: 700;
			cursor: pointer;
		}
	}
}
