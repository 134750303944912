@use 'src/theme.scss' as theme;
@import 'src/components/Common/Card.module.scss';

.TranscriptCard {
	@extend .Card;
	@include theme.card-max-width(theme.$largeCardWidth);
	transform: rotate(-1deg);
	&:before {
		transform: rotate(-4deg);
	}
    > div {
        @include theme.flex-column;
        gap: theme.$cardContentGap;
    }
}

.TranscriptTitle {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	position: relative;
	text-align: center;
	z-index: 0;
	gap: theme.$cardContentGap;
	// margin: 30px 0 2px;
	padding: 10px 30px;
	@include theme.title-gen(1.8rem);
	letter-spacing: 1px;
	transform: rotate(1deg);
	background: theme.$paperBackground no-repeat;
	background-size: cover;
	box-shadow: theme.$shadow;
		&:before {
			position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		content: '';
		background: theme.$quinternaryColor no-repeat;
		mix-blend-mode: color;
		backface-visibility: hidden;
	}
	@include theme.bkgBlend(theme.$quinternaryColor, color);
}

.TitleContainer {
    @include theme.flex-row-centered;
    height: min-content;
    margin-bottom: 10px;
}

.Line {
    height: 1px;
    flex-grow: 1;
    background: theme.$secondaryColor;
}

.Title {
	@include theme.text-gen(1.6rem);
	margin-right: 10px;
	color: theme.$secondaryColor;
}

.SectionContent {
	$sectionWidth: 100%;
	$timecodeWidth: 15%;
	$textWidth: 85%;

	@include theme.flex-row-left;
	@include theme.text(1.2rem);
	color: theme.$black;
    width: $sectionWidth;
	& > * {
		margin: 5px 0;
	}

    .Timecode {
		width: $timecodeWidth;
        align-self: flex-start;
    }
    
    .Text {
        width: $textWidth;
    }
}
