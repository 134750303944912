@use 'src/theme.scss' as theme;
@use 'src/components/Common/Card.module.scss';

$formItemBorderRadius: 10px;
$formItemWidth: 90%;

.CardContent {
	@extend .Card;
	@include theme.flex-column;
}

.Form {
	@include theme.flex-column-centered;
	// min-width: 700px;
	align-self: center;
	gap: 15px;
	margin: 0 auto;
	@include theme.medium-screen {
		min-width: unset;
	}

	// @TODO remove once Card stops setting styles to all children
	:global(.iti__country-list) {
		text-align: left;
	}
	// @TODO remove once Card stops setting styles to all children
	:global(.iti__hide) {
		display: none;
	}

	button {
		align-self: center;
	}
}

legend {
	width: $formItemWidth;
}

.FormCheckBox,
.FormRadio {
	$labelSpacing: 10px;
	$inputSize: 1.2rem;

	@include theme.flex-row;
	align-items: center;
	color: theme.$mainColor;
	gap: $labelSpacing;

	.Input {
		width: $inputSize;
		height: $inputSize;
		margin: 0;
		accent-color: theme.$tertiaryColor;

		&:focus {
			outline: 1px solid theme.$tertiaryColor;
		}
	}

	.Label {
		display: inline;
	}
}

.FormSelect {
	@include theme.flex-column;
	gap: 5px;
	width: -webkit-fill-available;
	min-width: min(350px, 100%);

	select {
		color: theme.$mainColor;
		background-color: theme.$inputBkg;
		border-radius: 2px;
		border: 1px solid transparent;
		font-size: inherit;
		padding: 0.5rem;

		&:focus {
			outline: none;
			border-color: theme.$primaryColor;
		}
		@media (prefers-color-scheme: dark){
			color:theme.$white;
			border-color: theme.$dmColorZ4;
			background-color: theme.$dmColorZ4;
		}
		option{
			@media (prefers-color-scheme: dark){
				background-color: theme.$dmColorZ4;
			}
		}
	}
}

.FormField {
	@include theme.flex-column;
	gap: 5px;
	width: -webkit-fill-available;
	min-width: min(350px, 100%);

	.Input {
		border: 1px solid transparent;
		border-radius: 2px;
		font-size: 1rem;
		font-family: theme.$mainFont;
		padding: 10px 5px;
		box-sizing: border-box;
		transition: border-color theme.$transitionDuration;
		background-color: theme.$inputBkg;
		&:focus {
			outline: none;
			border-color: theme.$primaryColor;
		}

		&::placeholder {
			opacity: 0.7;
			font-style: italic;
			padding-left: 5px;
		}
		@media (prefers-color-scheme: dark){
			color:theme.$white;
			background-color: theme.$dmColorZ4;
		}
	}
}

.ErrorText {
	color: theme.$errorColor;
	margin: 0.5rem 0;
}

.FieldError {
	.Input {
		border-color: theme.$errorColor;
	}
	.Label {
		color: theme.$errorColor;
	}
}



.Label {
	display: block;
	color: theme.$quaternaryColor;
	@media (prefers-color-scheme: dark){
		color:theme.$white;
	}
}