@use 'src/theme.scss' as theme;
@use "src/components/Common/Card.module.scss";

$numberContainerSize: 3rem;

.PreferencesCard, .CheckCodeCard {
    @extend .Card;
    @include theme.flex-column-centered;
    @include theme.text-gen(0.9rem);
    @include theme.card-max-width(theme.$mediumCardWidth);

    h4 {
        @include theme.title;
        font-size: 1rem;
    }
}

.RequestCodeContainer, .CheckCodeContainer {
    @include theme.flex-column-centered;
    gap: theme.$cardContentGap;
}

.OrderedList {
    @include theme.flex-column;
    gap: theme.$cardContentGap;
    list-style: none;
    counter-reset: list-number;
    max-width: min(95vw, 400px);
    width: 100%;

    div {
        counter-increment: list-number;
        @include theme.flex-row-left;
        gap: 10px;
    }
}

.RoundedNumber {
    @include theme.flex-column-centered;
    
    &::before {
        content: counter(list-number);
        @include theme.flex-column-centered;
        @include theme.title-gen(1.5rem);
        align-self: flex-start;
        color: theme.$white;
        border: 2px solid theme.$white;
        border-radius: 50%;
        min-width: $numberContainerSize;
        max-width: $numberContainerSize;
        min-height: $numberContainerSize;
        max-height: $numberContainerSize;
        background: theme.$secondaryColor;
        background-clip: content-box;
        @media (prefers-color-scheme: dark){
			background-color: theme.$dmColorZ3;
		}
    }
}