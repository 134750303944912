@use 'src/theme.scss' as theme;

.Page {
	@include theme.flex-column;
	align-items: center;
	justify-content: space-between;
	position: relative;

	/* Attention:
	/* The below block of opacity/transition settings is to facilitate embedding the player without flickering.
	/* We need to hide the 'real' background of the page until we're sure which mode we're in. Fading the loader out helps acheive this without flickering. */
	opacity: 0;
	transition: opacity 1.25s ease-in;
	&.Loaded {
		opacity: 1;
	}

	background: theme.$pageBackground;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	overflow-x: hidden;
	box-sizing: border-box;
	min-height: 100vh;
	width: 100vw;
	/* mobile viewport bug fix https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/ */
	/* Avoid Chrome to see Safari hack */
	@supports (-webkit-touch-callout: none) {
		/* The hack for Safari */
		min-height: -webkit-fill-available;
	}

	@include theme.large-screen {
		background-attachment: fixed;
	}

	@media (prefers-color-scheme: dark) {
		background-color: theme.$dmColorZ0;
	}

	.Header {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		height: 70px;
		width: 100%;
		margin-bottom: theme.$headerFooterPadding;
		background: theme.$headerBackground;
		background-size: cover;
		color: theme.$white;

		@include theme.small-screen {
			z-index: 2;
			height: 60px;
			justify-content: center;
			transition: transform theme.$navAndVideoTransition;
			transition-delay: theme.$delayBeforeNavAndVideoTransition;

			/* if the video is playing OR in voting sequence */
			:global(body.playing) &,
			:global(body.voting) & {
				transform: translateY(-100%);
			}
		}

		h1 {
			@include theme.title-gen(1.5rem);
			line-height: 1.75rem;
			display: none;
			margin: 0 0;
			padding: 14px 30px;
			box-sizing: border-box;
			text-decoration: none;
			text-transform: uppercase;
			@include theme.small-screen {
				text-align: center;
			}
			svg {
				margin: 3px 1em 3px 0;
			}
		}
		@media (prefers-color-scheme: dark) {
			background-color: theme.$dmColorZ1;
		}
	}

	.Logo {
		/* ReactSVG wraps the svg in a blank div. So, target it to fill it with the background color instead of the svg itself*/
		& > div {
			display: flex;
			align-items: center;
			padding: 0 20px;
		}
	}

	.Footer {
		@include theme.text-gen(0.9rem, 400);
		// color: theme.$white !important;
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		width: 100%;
		@include theme.flex-column;
		gap: 5px;
		margin-top: theme.$headerFooterPadding;
		padding: 10px 20px;
		box-sizing: border-box;
		background: theme.$footerBackground;
		background-size: cover;
		@include theme.small-screen {
			padding: 10px;
			transition: transform theme.$navAndVideoTransition;
			transition-delay: theme.$delayBeforeNavAndVideoTransition;
			/* if the video is playing OR in voting sequence */
			:global(body.playing) &,
			:global(body.voting) & {
				transform: translateY(100%);
			}
		}

		& > * {
			flex: 2;
		}

		.MadeWithAndCopyright {
			@include theme.flex-row-centered;
			justify-content: space-between;
			width: 100%;
			filter: opacity(0.8);
			font-size: 0.8rem;
		}
		@media (prefers-color-scheme: dark) {
			background-color: theme.$dmColorZ1;
		}
	}
}

.FooterLinks {
	@include theme.flex-row-centered;
	justify-content: space-between;
	width: 100%;
	text-align: end;
	text-decoration: underline;
	& > div {
		font-family: theme.$mainFont !important;
		font-weight: 400 !important;
		text-align: right;
		strong {
			font-weight: 100;
			// color: theme.$black;
			text-decoration-color: theme.grey();
			cursor: pointer;
		}
	}
}
