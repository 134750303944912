@import 'base';


@mixin card-max-width($max: 700px) {
	max-width: min(85%, $max);
	width: fit-content;
}

////////////////////////////////////////////////////
//  ------------------------------------------------
//  THEME VARS & MIXINS
//  ------------------------------------------------
////////////////////////////////////////////////////

//---------------------------------
//  COLORS
//---------------------------------
$transparencyHigh: 0.6;
$transparencyMed: 0.75;
$transparencyLow: 0.9;

$mainColor: #333333;
$primaryColor: rgba(38, 37, 61, 1);
$secondaryColor: rgba(203, 175, 149, 1);
$tertiaryColor: rgba(64, 63, 103, 1);
$quaternaryColor: rgba(119, 112, 106, 1);
$quinternaryColor: rgba(203, 175, 149, 1);
$titleColor: $primaryColor;
$white: #fff;
$black: rgba(51, 51, 51, 1);
$errorColor: rgba(245, 76, 96, 1);
$inputBkg: rgba(247, 241, 236, 1);

@function grey($opacity: 1) {
	@return rgba(119, 119, 119, $opacity);
}



//---------------------------------
//  SHADOWS
//---------------------------------
$shadow: -3px -3px 10px rgba(0, 0, 0, 0.15);
$lightShadow: 0px 1px 10px rgba(0, 0, 0, 0.15);

//---------------------------------
//  TRANSITION
//---------------------------------
$transitionDuration: 0.5s;

//---------------------------------
//  BACKGROUNDS
//---------------------------------

// Paper backgrounds
// 04,  08,  09, 11, 12
$paperBackground: url('/static/img/bg.svg');
$paperBackground1: url('/static/img/bg.jpg');
$paperBackground2: url('/static/img/paper-bg2.png');
$paperBackground3: url('/static/img/paper-bg3.png');

// Page
$pageBackground: transparent; // $paperBackground;
$headerBackground: rgb(63, 64, 103);
$footerBackground: #fafafabc;

// Mixin paperBackround before blend mode
@mixin bkgBlend($beforeBkg: $primaryColor, $blendMode: hard-light, $dmColor: $dmColorZ2) {
	background: $paperBackground no-repeat;
	background-size: cover;
	&:before {
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		content: '';
		background: $beforeBkg;
		mix-blend-mode: $blendMode;
		backface-visibility: hidden;
	}
	@media (prefers-color-scheme: dark){
		background:$dmColor;
		&:before{background:none;}
	}
}

//---------------------------------
//  CARDS
//---------------------------------
$cardBeforeBackground: url('/static/img/card-before-bg-min.png');
$cardContentBackground: url('/static/img/card-content-bg-min.png');
$cardShadow: $shadow;
$cardSpacing: 30px; // used for margins / paddings / gutter between Cards and their content
$smallCardSpacing: 20px;

// $cardSpacing: 3rem; // used for margins / paddings / gutter between Cards and their content

$smallCardWidth: 360px;
$mediumCardWidth: 500px;
$largeCardWidth: 700px;
$extraLargeCardWidth: 950px;

$cardContentGap: 20px;

//---------------------------------
//  FONTS
//---------------------------------
$mainFont: 'Apercu', sans-serif;
$mainFontBold: 'Apercu Bold', sans-serif;
$secondaryFont: 'MaaxMono', Monospace, sans-serif;
$titleFont: $secondaryFont;

@mixin title {
	font-family: $titleFont;
	font-weight: 400;
	line-height: normal;
	color: $titleColor;
	@media (prefers-color-scheme: dark){
		color: $white;
	}
}
@mixin title-gen($fontSize: 1.25rem, $fontWeight: 400, $color: $titleColor) {
	@include title;
	font-size: $fontSize;
	font-weight: $fontWeight;
	color: $color;
	@media (prefers-color-scheme: dark){
		color: $white;
	}
}

@mixin text($color: $black) {
	font-family: $mainFont;
	line-height: normal;
	color: $color;
	@media (prefers-color-scheme: dark){
		color: $white;
	}
}

@mixin text-gen($fontSize: 8px, $fontWeight: 400) {
	font-family: $mainFont;
	line-height: normal;
	font-size: $fontSize;
	font-weight: $fontWeight;
	@media (prefers-color-scheme: dark){
		color: $white;
	}
}

//---------------------------------
//  Dark mode
//---------------------------------
$dmColorZ0: #000000;
$dmColorZ1: #141414;
$dmColorZ2: #252525;
$dmColorZ3: #313131;
$dmColorZ4: #4b4b4b;