@use "src/theme.scss" as theme;
@use "src/components/Common/Card.module.scss";

.TutorialCard {
	@extend .Card;
	@include theme.card-max-width(theme.$mediumCardWidth);

	.CardContent {
		@include theme.flex-column-centered;
		gap: theme.$cardContentGap;
		min-width: 300px;
		// @include theme.scroll-bar(20vh);
	}

	h2 {
		z-index: 1;
		@include theme.flex-column-centered;
		background: theme.$paperBackground no-repeat;
		min-height: 50px;
		padding: 15px 40px;
		text-align: center;
		transform: rotate(-1deg);
		box-sizing: border-box;
		background-size: cover;
		box-shadow: theme.$shadow;
		text-transform: uppercase;
		&:before {
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			content: '';
			background: theme.$quinternaryColor no-repeat;
			mix-blend-mode: color;
			backface-visibility: hidden;
		}
	}

	h3 {
		margin: 0;
	}
}

.LinesContainer {
	@include theme.flex-column-centered;
	gap: theme.$cardContentGap;

	@include theme.large-screen {
		@include theme.flex-row;
		width: theme.$extraLargeCardWidth;
		flex-wrap: wrap;
		justify-content: center;
	}
}

.Section {
	@include theme.flex-column-centered;
	text-align: center;
	gap: 20px;
	& > svg {
		width: 35px;
		height: auto;
	}
	@include theme.large-screen {
		width: 200px;
		// flex-basis: 200px; // @fixme: removing for now because the 'quinconces' align broke during big refactors and it had too much space. Can reintroduce later if we fix it
		padding: 0 30px 30px;
	}
}

.LineContent {
	@include theme.flex-column-centered;
	gap: 10px;
}

.LineExplanation {
	@include theme.text-gen(1rem);
}