@use "src/theme.scss" as theme;

.VoteContainer {
	@include theme.flex-column-centered;
	justify-content: space-evenly;
	width: theme.$playerWidth;
	height: theme.$playerHeight;
	overflow: hidden;
	user-select: none;
	position: absolute;
	top: 0;
	z-index: 5;
	background: theme.$paperBackground3 no-repeat;
	background-size: cover;
	box-shadow: theme.$shadow;
}

.VoteContainer > div {
	width: 100%;
}

.QuestionContainer {
	@include theme.flex-column-centered;
	justify-content: flex-end;
	padding: 10px auto;
	max-height: 40%;
}

.QuestionText {
	text-align: center;
	color: theme.$white;
	font-size: 1.1rem;
}

.AnswerContainer {
	@include theme.flex-column-centered;
	height: 50%;
}

.AnswerContainer > * {
	margin: 1vh 0;
	width: 100%;
	transform: rotate(-1deg);
	&:nth-child(odd) {
		transform: rotate(1deg);
	}
}

.VoteButton {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 60px;
	position: relative;
	z-index: 1;
	width: 90%;
	padding: 10px 40px;
	text-decoration: none;
	font-family: theme.$secondaryFont;
	font-size: 1rem;
	color: theme.$primaryColor;
	margin: 0 auto;
	cursor: pointer;
	transition: all theme.$transitionDuration;
	border: none;
	outline: none;
	box-shadow: theme.$shadow;
	@include theme.bkgBlend(theme.$white, color);
	@media (prefers-color-scheme: dark){
		color: theme.$white;
	}

	&:hover,
	&:focus,
	&:active {
		transform: scale(1.05);
	}
}

.Clicked,
.Clicked:hover {
	&:before {
		background: theme.$secondaryColor;
	}
}

.playkit-playback-controls.playkit-center-playback-controls {
	display: none !important;
}
