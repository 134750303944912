@use 'src/theme.scss' as theme;
@import 'src/components/Common/Card.module.scss';

.LinkCard {
	@extend .Card;
	cursor: pointer;
}

.ListsContainer {
    @include theme.flex-column-centered;
    gap: theme.$cardContentGap;
}

.contentList {
	display: flex;
	flex-direction: column;
	width: 100%;
	&:not(:last-of-type) {
		margin-bottom: 30px;
	}
	&:nth-child(odd){
		.listHeader{
			transform: rotate(1deg);
		}
	}
}

.listHeader {
	margin-bottom: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 40px;
	padding: 15px 20px;
	margin: -20px auto 20px;
	@include theme.title;
	font-size: 1.8rem;
	text-align: center;
	transform: rotate(-1deg);
	box-sizing: border-box;
	background-size: cover;
	box-shadow: theme.$shadow;
	@include theme.bkgBlend(theme.$quinternaryColor, color, theme.$dmColorZ2);
	@include theme.large-screen {
		min-height: 60px;
		padding-left: 30px;
		padding-right: 30px;
	}
}

.label {
	margin: 0;
}
// .line {
// }

.list {
	display: grid;
	grid: auto-flow / 1fr 1fr 1fr 1fr;
	// Remove columns as we go down in screen sizes
	@include theme.large-screen {
		grid: auto-flow / 1fr 1fr 1fr;
	}

	@include theme.medium-screen {
		grid: auto-flow / 1fr;
	}

	@include theme.small-screen {
		grid: auto-flow / 1fr;
	}
	gap: 20px;
}

.thumbnailTile {
	background-color: theme.$white;
	box-shadow: theme.$lightShadow;
	display: flex;
	align-items: center;
	justify-content: stretch;
	color: currentColor;
	text-decoration: none;
	width: 100%;
	// height: 100%; Causes issues on Safari as of June 2022
	@media (prefers-color-scheme: dark){
		background: theme.$dmColorZ2;
	}
	&:hover {
		position: relative;
		box-shadow: theme.$shadow;
	}

	> img {
		width: 90px;
		height: 100%;
		max-height: 90px;
		object-fit: cover;
		object-position: 0 5%;
	}

	.content {
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		justify-content: space-between;
		min-height: 90px;
		margin: 0 10px;
		gap: 10px;

		p {
			@include theme.text;
			font-size: 0.85rem;
			text-align: left;
			color: theme.grey();
			margin: 0;
			display: -webkit-box;
			$linesToClamp: 3;
			$lineHeight: 1; // rem
			max-height: $linesToClamp * $lineHeight +rem;
			line-height: $lineHeight +rem;
			-webkit-line-clamp: $linesToClamp;
			-webkit-box-orient: vertical;
			overflow: hidden;
			flex-grow: 1;
			@include theme.small-screen {
				display: none;
			}
		}

		.title {
			@include theme.text-gen(0.9rem, 700);
			margin: 5px 0;
			color: theme.$black;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		.meta {
			@include theme.text;
			font-size: 0.75rem;
			font-weight: 400;
			color: theme.grey();
			display: flex;
			align-items: flex-end;
			flex-grow: 0;
			justify-content: space-between;

			&:last-child {
				margin-bottom: 5px;
			}

			.provider {
				@include theme.text-gen(0.75rem, 400);
				display: inline-flex;
				align-items: flex-end;

				// &:after {
				//   content: "|";
				//   display: inline-block;
				//   margin: 0 0.5em;
				// }

				img {
					width: 16px;
					height: 16px;
					object-fit: cover;
					margin-right: 0.5em;
					@include theme.small-screen {
						display: none;
					}
				}
			}

			.date {
				white-space: nowrap;
				&::first-letter {
					text-transform: uppercase;
				}
			}
		}

		.type {
			display: inline-block;
			background: theme.$primaryColor;
			opacity: 0.9;
			@include theme.text-gen(0.65rem, 500);
			color: theme.$white;
			margin-bottom: 5px;
			max-width: max-content;
			padding: 0 8px;
			@media (prefers-color-scheme: dark){
				background: theme.$dmColorZ4;
			}
		}
	}

	.arrow {
		margin-right: 10px;
		flex-shrink: 0;
		display: none;
	}

	@include theme.large-screen {
		flex-direction: column;
		> img {
			width: 100%;
			height: 170px;
			margin-right: 0;
			max-height: unset;
		}
		> img + .content .info {
			margin-top: calc(-1.2em - 5px);
		}
		.content {
			margin: 5px 10px;
			.info {
				order: -1;
				.meta {
					flex-direction: column;
					align-items: flex-start;
					.provider {
						color: theme.$white;
						background-color: theme.grey(0.85);
						color: theme.$white;
						border-radius: 3px;
						padding: 4px 10px;
						img {
							border-top-left-radius: 3px;
							border-bottom-left-radius: 3px;
						}
						&:after {
							content: ' ';
						}
					}
					.date {
						margin-top: 5px;
					}
				}
				.type {
					margin-top: -1.2em;
					margin-bottom: 0;
					float: right;
				}
			}
			p {
				margin-bottom: 5px;
			}
		}
		.arrow {
			display: none;
		}
	}
}
