@use "src/theme.scss" as theme;
@use "src/components/Common/Card.module.scss";

@mixin ActionIcon {
	height: 48px;
	width: 64px;
}

@keyframes rotateClockwise {
	0% {
		transform: rotateZ(0deg) scale(1);
	}
	50% {
		transform: rotateZ(10deg) scale(1.1);
	}
	100% {
		transform: rotateZ(0deg) scale(1);
	}
}
@keyframes rotateCounterClockwise {
	0% {
		transform: rotateZ(0deg) scale(1);
	}
	50% {
		transform: rotateZ(-10deg) scale(1.1);
	}
	100% {
		transform: rotateZ(0deg) scale(1);
	}
}
@keyframes rotateBadButtonSelected {
	0% {
		transform: rotate(0) scale(1);
	}
	20% {
		transform: rotate(-5deg) scale(1);
	}
	50% {
		transform: rotate(20deg) scale(1);
	}
	100% {
		transform: rotate(0) scale(1);
	}
}

@keyframes rotateGoodButtonSelected {
	0% {
		transform: rotate(0) scale(1);
	}
	20% {
		transform: rotate(5deg) scale(1);
	}
	50% {
		transform: rotate(-20deg) scale(1);
	}
	100% {
		transform: rotate(0) scale(1);
	}
}

.Landing {
	@extend .Card;
	@include theme.card-max-width(theme.$largeCardWidth);
	width: calc(100% - 40px);

		.Content {
			@include theme.flex-row-centered;
			gap: theme.$cardContentGap;
			justify-content: space-between;
			width: 100%;
			justify-content: space-around;
			gap: 1.5rem;
			@include theme.medium-screen {
			@include theme.flex-column-centered;
			align-items: stretch;
		}
	}

	header {
		@include theme.extra-small-screen {
			display: none;
		}
	}

	.WelcomeBack {
		align-self: center;
		@include theme.title;
		font-size: 4.6rem;
		width: 50%; // 50% is shared with .Actions so that together, they can only take up to 100%, hence never growing bigger than their container // we want to avoid that behaviour on small screens, hence a media query below
		width: max-content;
		span {
			display: block;
			color: theme.$tertiaryColor;
			min-width: max-content;
			@media (prefers-color-scheme: dark){
				color: theme.$white;
			}
		}
		:nth-child(2) {
			color: theme.$secondaryColor;
			@media (prefers-color-scheme: dark){
				color: theme.$white;
			}
		}
	}


	.RatingContainer {
		@include theme.flex-column-centered;
		gap: theme.$cardContentGap;
	}

	.Rating {
		margin: 0 auto;
		text-align: center;
		width: 50%; // 50% is shared with .Actions so that together, they can only take up to 100%, hence never growing bigger than their container // we want to avoid that behaviour on small screens, hence a media query below

		@include theme.medium-screen {
			width: 70%;
		}

		img {
			cursor: pointer;
			margin: 0 15px;
		}

		.Icons {
			transition: all 0.15s;
			transform: scale(0.85);
			@include theme.flex-row;
			max-width: min(100%, 300px);
			justify-content: space-around;

			&:hover,
			&.Selected{
				transform: scale(1);
			}
			&.Bad {
				padding-top: 10px; // prevent an optical illusion making thumbs look like they're not aligned
				&.Selected {
					transform-origin:0 50%;
					animation: rotateBadButtonSelected 1.5s 2 ease;
				}
			}
			&.Good {
				padding-bottom: 10px; // prevent an optical illusion making thumbs look like they're not aligned
				&.Selected {
					transform-origin:0 50%;
					animation: rotateGoodButtonSelected 1.5s 2 ease;
				}
			}
		}
	}

	.RatingIcons { // container for both thumbs
		@include theme.flex-row-centered;
		min-width: 70%;
	}

	.Actions {
		@include theme.flex-column-centered;
		gap: theme.$cardContentGap;
		align-items: stretch;
		width: 50%; // 50% is shared with .Rating so that together, they can only take up to 100%, hence never growing bigger than their container
		@include theme.medium-screen {
			margin: 0 auto;
			width: min(100%, 300px);
		}
	}

	.ActionLink {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 60px;
		max-width: 500px;
		position: relative;
		padding: 15px 20px;
		text-decoration: none;
		cursor: pointer;
		font-family: theme.$secondaryFont;
		font-size: 1.3rem;
		text-align: center;
		box-sizing: border-box;
		transition: all theme.$transitionDuration;
		box-shadow: theme.$shadow;
		@include theme.bkgBlend(theme.$primaryColor, hard-light, theme.$dmColorZ3);

		@include theme.large-screen {
			padding-left: 40px;
			padding-right: 40px;
		}

		&:nth-child(1) {
			transform: rotate(-1deg);
			color: theme.$white;
			@include theme.large-screen {
				margin-right: 40px;
			}
		}

		&:nth-child(2) {
			transform: rotate(1deg);
			color: theme.$primaryColor;
			&:before {
				background: theme.$secondaryColor;
			}
			@media (prefers-color-scheme: dark){
				color: theme.$white;
				&:before {
					background: theme.$dmColorZ4;
				}
			}
			@include theme.large-screen {
				margin-left: 40px;
			}
		}

		&:nth-child(3) {
			transform: rotate(-1deg);
			color: theme.$primaryColor;
			&:before {
				background: theme.$quaternaryColor;
			}
			@media (prefers-color-scheme: dark){
				color: theme.$white;
				&:before {
					background: theme.$dmColorZ4;
				}
			}
			@include theme.large-screen {
				margin-left: 20px;
				margin-right: 20px;
			}
		}

		&:hover,
		&:focus,
		&:active {
			transform: scale(1.1);
		}

		.ActionIcon {
			@include ActionIcon();
			margin-right: 10px;
		}

		button {
			border: none;
			background-color: theme.$white;
		}
		picture {
			position: relative;
			display: inline-flex;
			border-radius: 4px;

			img {
				@include ActionIcon();
				object-fit: cover;
				object-position: left 15%;
				border-radius: inherit;
			}
		}

		svg {
			display: none;
		}
	}
}

.ShareActions {
	@include theme.flex-row;

	.ActionLink {
		flex-direction: column;
		align-items: center;
		&:not(:nth-child(1)) {
			margin-left: 15px;
		}
	}

	// this is to remove "copy link" when on mobile - right now, this feature is not working
	@include theme.small-screen {
		:nth-child(3) {
			display: none;
		}
	}
}
